<template>
    <div class="container caixa-dados-agente-container" v-if="agente">

        <div class="row align-items-center pb-4">
            <div class="caixa-dados-agente-container-imagem">
                <img class="caixa-dados-agente-imagem" src="@/assets/images/agentes/icone_pagina_agentes.png" />
            </div>
        </div>

        <div class="row pb-3">
            <h4 class="caixa-dados-agente-titulo"> {{ agente.nome }} </h4>
        </div>

        <div class="row">
            <span class="caixa-dados-agente-paragrafo-grande"> CNPJ: {{ agente.cnpj }} </span>
        </div>

        <div class="row pb-3">
            <span class="caixa-dados-agente-paragrafo-grande"> Razão Social: {{ agente.razao_social }} </span>
        </div>

        <div class="row ">
            <span class="caixa-dados-agente-paragrafo-grande"> {{ `${agente.endereco} - ${agente.complemento?agente.complemento:''}` }} </span>
        </div>
        
        <div class="row pb-3">
            <p class="caixa-dados-agente-paragrafo-grande"> {{ `${agente.bairro}, ${agente.cidade} - ${agente.estado}` }} </p>
        </div>

        <div class="row justify-content-start pb-1">
            <div class="col-12 pl-0 mt-1">
                <p class="caixa-dados-agente-texto-pequeno text-nowrap">
                    <span class="caixa-dados-agente-icone"><img class="caixa-dados-agente-img" src="@/assets/images/agentes/icone_telefone_agentes.png" width="100"/></span>
                    <span class="text-nowrap">{{ agente.telefone? agente.telefone.length > 1 ? agente.telefone : 'Não disponível': 'Não disponível' }}</span>
                </p>
            </div>
            <div class="col-12 pl-0 mt-1">
                <p class="caixa-dados-agente-texto-pequeno text-nowrap">
                    <a :href="`https://api.whatsapp.com/send?phone=55${agente.whatsapp}`" target="_blank" class="caixa-dados-agente-link-whats">
                        <span class="caixa-dados-agente-icone"><img class="caixa-dados-agente-img" src="@/assets/images/agentes/icone_whats_agentes.png" width="100"/></span>
                        <span class="text-nowrap">{{ agente.whatsapp? agente.whatsapp.length > 1 ? agente.whatsapp : 'Não disponível': 'Não disponível' }}</span>
                    </a>
                </p>
            </div>
        </div>

        <div>
            <div class="row">
                <p class="caixa-dados-agente-paragrafo-grande pb-2">{{ agente.email }}</p>
            </div>
        </div>

        <div class="row">
            <botao-m class="caixa-dados-agente-botao-acessar my-2" @click="exibeModalAreasAtuacao">
                Área de atuação
            </botao-m>
        </div>

        <modal-areas-de-atuacao ref="modal_areas_atuacao" v-model="modalAreasAtuacaoShow" />
    </div>
</template>

<script>
import BotaoM from '@/components/botoes/BotaoM';
import ModalAreasDeAtuacao from '@/components/agentes-components/ModalAreasDeAtuacao';
import EstadosCidadesServices from '@/assets/js/EstadosCidadesServices'
import { formatarTelefone } from '@/assets/js/SharkUtils';

export default {
  name: 'CaixaDadosAgente',
  components: {
      BotaoM,
      ModalAreasDeAtuacao,
  },
  props: {
    agente: Object,
  },
  data: function() {
      return {
          modalAreasAtuacaoShow: false,
      }
  },
  computed: {
    whatsapp() {
        if(this.agente) {
            return this.formatarTelefone(this.agente.whatsapp);
        } else {
            return '';
        }
    },
    telefone() {
        if(this.agente) {
            return this.formatarTelefone(this.agente.telefone);
        } else {
            return '';
        }
    },
  },
  methods: {
      formatarTelefone: function(numeroTel) {
        return formatarTelefone(numeroTel);
      },
      exibeModalAreasAtuacao: function() {
        this.$refs.modal_areas_atuacao.cidades_atuacao = this.agente.cidades_atuacao
                                                            .map(cidad => EstadosCidadesServices.dadosCidade(cidad.cod_cidade_ibge).name)
                                                            .filter(cidade => cidade != null)
                                                            .sort((cidade1, cidade2) => cidade1 > cidade2? 1:-1);

        this.modalAreasAtuacaoShow = true;
        this.$refs.modal_areas_atuacao.show();
      },
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.caixa-dados-agente-container{
    width: 100%;
}

.caixa-dados-agente-titulo {
    color: #fff;
    font-weight: 900;
    font-family: Museo;
}

.caixa-dados-agente-paragrafo-grande {
    color: #fff;
    font-size: 15px;
}

.caixa-dados-agente-texto-pequeno,
.caixa-dados-agente-botao-acessar {
    font-size: 14px;
    font-weight:800;
}

.caixa-dados-agente-paragrafo-grande,
.caixa-dados-agente-texto-pequeno {
    margin-bottom: 1px;
    color: #fff;
}

.caixa-dados-agente-botao-acessar {
    height: 50px !important;
    padding-left: 5em !important;
    padding-right: 5em !important;
}

.caixa-dados-agente-icone {
    max-height: 15px;
    margin-right: 6px;
}

.caixa-dados-agente-img {
    width: auto;
    height: 1.8rem;
    position: relative;
    top: -2px;
}

.caixa-dados-agente-container-imagem {
    width: 100%;
    height: auto;
}

.caixa-dados-agente-imagem {
    width: 70px;
}

.caixa-dados-agente-link-whats {
    color: inherit;
}

</style>