<template>
    <b-modal 
        modal-class="modal-area-de-atuacao-modal-class"
        v-model="exibe" 
        title="Áreas de atuação"
        body-class="modal-area-de-atuacao-body-class" 
        header-class="modal-area-de-atuacao-header-class" 
        footer-class="modal-area-de-atuacao-footer-class" 
        header-close-variant="light"
    >
        
        <template v-slot:default>
            <div class="container">

                <div class="row">
                    <div class="col-12">
                        
                        <div class="modal-area-de-atuacao-scroll-container">
                            <div v-for="(cidade, i) in cidades_atuacao" v-bind:key="i">
                                <p> {{ cidade }} </p>
                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">
                
            </div>
        </template>

        <template v-slot:modal-header-close>
            <div class="modal-areas-de-atuacao-header-close">
                X
            </div>
        </template>

    </b-modal>
</template>

<script>

export default {
    name: 'ModalAreaDeAtuacao',
    components: {
    },
    props: {
    },
    data: function() {
        return {
            exibe: false,
            cidades_atuacao: [],
        }
    },
    computed: {
    },
    methods: {
        show: function() {
            this.exibe = true;
            console.log('cidades dentro', this.cidades_atuacao);
        },
        hide: function() {
            this.exibe = false;
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.modal-area-de-atuacao-header-class {
    border: var(--azul-claro-omni) !important;
    background-color: var(--azul-claro-omni);
    color: white;
    border: 1px solid white;
}

.modal-area-de-atuacao-body-class {
    padding-right: 50px !important;
    padding-left: 50px !important;
    padding-top: 40px !important;
}

.modal-area-de-atuacao-footer-class {
    border: white !important;
    height: 1px;
}

.modal-areas-de-atuacao-header-close {
    color: white;
}

.modal-areas-de-atuacao-header-close:hover {
    color: white;
}

.modal-area-de-atuacao-scroll-container {
    overflow-y: auto;
    height: 200px;
    padding-right: 4px;
    color: #000;
    scrollbar-width: thin;
    scrollbar-color: var(--laranja-claro-omni) lightgrey;
}
    .modal-area-de-atuacao-scroll-container::-webkit-scrollbar-track {
        background: lightgrey; 
        border-radius: 0.4em;
    }
    .modal-area-de-atuacao-scroll-container::-webkit-scrollbar-thumb {
        background: var(--laranja-claro-omni);
        border-radius: 0.4em;
    } 
    .modal-area-de-atuacao-scroll-container::-webkit-scrollbar {
        width: .8em;
    }

.modal-area-de-atuacao-modal-class .modal-content {
    border: none;
    border-radius: 0;
}

</style>